<template>
  <div>
    <v-container>
      <v-row>
          <v-col cols="12">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                  color="red"
                >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 2"
                  step="2"
                  color="red"
                >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 3"
                  step="3"
                  color="red"
                >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step color="red" step="4">
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card
                    flat
                    class="d-flex align-center justify-center"
                    height="100%"
                  >
                  <v-container fluid>
                    <v-row class="d-flex align-center justify-center">
                      <v-col cols="12">
                        <div class="d-flex flex-column">
                          <v-card-title class="headline font-weight-light pa-0 text-wrap">
                            <p>Шаг 1. Ознакомиться с классом пожара.</p>
                          </v-card-title>
                          <v-divider></v-divider>
                          <!-- <v-card-title class="headline font-weight-light pt-5 text-wrap">
                            <p></p>
                          </v-card-title> -->
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="d-flex flex-column justify-center align-center">
                          <v-dialog
                            class="d-flex justify-center"
                            v-model="dialog1"
                            width="70%"
                          >
                              <template v-slot:activator="{ on }">
                                <v-img
                                  lazy-src="/img/material/class-fire.png"
                                  width="70%"
                                  height="80%"
                                  src="/img/material/class-fire.png"
                                  contain
                                  style="cursor:pointer"
                                  v-on="on"
                                ></v-img>
                              </template>
                              <v-card>
                                <v-card-action class="d-flex justify-end">
                                  <v-card-text class="title font-weight-light">Шаг 1.Ознакомиться с классом пожара</v-card-text>
                                    <v-btn
                                      class="ma-2"
                                      color="red"
                                      outlined
                                      dark
                                      @click="dialog1 = false"
                                    >
                                      Закрыть
                                      <v-icon
                                        dark
                                        right
                                      >
                                        mdi-cancel
                                      </v-icon>
                                    </v-btn>
                                </v-card-action>

                                  <v-img
                                    lazy-src="/img/material/class-fire.png"
                                    height="800"
                                    width="100%"
                                    src="/img/material/class-fire.png"
                                    contain

                                  ></v-img>
                              </v-card>
                            </v-dialog>
                            <v-sheet class="d-flex justify-center">
                              <v-card flat>

                                <v-card-title class=" title font-weight-light">
                                  <v-icon color="red" size="40" class='mr-3'>mdi-magnify-scan</v-icon>
                                  Нажмите на изображение для увеличения
                                </v-card-title>
                              </v-card>
                            </v-sheet>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  </v-card>
                  <div width="100%" class="d-flex justify-end">
                    <v-btn
                      color="red"
                      dark
                      @click="e1 = 2"
                      class="text-right"
                    >
                      Далее
                    </v-btn>
                  </div>

                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card
                    flat
                    class="d-flex align-center justify-center"
                    height="100%"
                  >
                  <v-container>
                    <v-row class="d-flex align-center justify-center">
                      <v-col cols="12">
                        <div class="d-flex flex-column">
                          <v-card-title class="headline font-weight-light pa-0 text-wrap">
                            <p>Шаг 2. Выбрать подходящий тип огнетушителя,  соответствующий классу пожара</p>
                          </v-card-title>
                          <v-divider></v-divider>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="d-flex flex-column justify-center align-center">
                          <v-dialog
                            class="d-flex justify-center"
                            v-model="dialog2"
                            max-width="70%"
                          >
                              <template v-slot:activator="{ on }">
                                <v-img
                                  lazy-src="/img/material/choice.png"
                                  width="70%"
                                  height="100%"
                                  src="/img/material/choice.png"
                                  contain
                                  v-on="on"
                                  style="cursor:pointer"
                                ></v-img>
                              </template>
                              <v-card>
                                <v-card-action class="d-flex justify-end">
                                  <v-card-text class="title font-weight-light">
                                    Шаг. 2 Выбрать подходящий тип огнетушителя, соответствующий классу пожара
                                  </v-card-text>
                                    <v-btn
                                      class="ma-2"
                                      color="red"
                                      dark
                                      @click="dialog2 = false"
                                    >
                                      Закрыть
                                      <v-icon
                                        dark
                                        right
                                      >
                                        mdi-cancel
                                      </v-icon>
                                    </v-btn>
                                </v-card-action>

                                  <v-img
                                    lazy-src="/img/material/choice.png"
                                    height="800"
                                    width="100%"
                                    src="/img/material/choice.png"
                                    contain
                                  ></v-img>
                              </v-card>
                            </v-dialog>
                            <v-sheet class="d-flex justify-center">
                              <v-card flat>

                                <v-card-title class=" title font-weight-light">
                                  <v-icon color="red" size="40" class='mr-3'>mdi-magnify-scan</v-icon>
                                  Нажмите на изображение для увеличения
                                </v-card-title>
                              </v-card>
                            </v-sheet>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  </v-card>

                  <div width="100%" class="d-flex justify-end">
                    <v-btn
                      color="red"
                      dark
                      @click="e1 = 3"
                    >
                      Далее
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-card
                    flat
                    class="d-flex align-center justify-center"
                    height="100%"
                  >
                  <v-container>
                    <v-row class="d-flex align-center justify-center">
                      <v-col cols="12">
                        <div class="d-flex flex-column">
                          <v-card-title class="headline font-weight-light pa-0 text-wrap">
                            <p>Шаг 3. Нажать на кнопку "Потушить возгорание"</p>
                          </v-card-title>
                          <v-divider></v-divider>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="d-flex flex-column justify-center align-center">
                          <v-dialog
                            class="d-flex justify-center"
                            v-model="dialog3"
                            width="70%"
                          >
                              <template v-slot:activator="{ on }">
                                <v-img
                                  lazy-src="/img/material/check.png"
                                  width="70%"
                                  height="100%"
                                  src="/img/material/check.png"
                                  contain
                                  v-on="on"
                                  style="cursor:pointer"
                                ></v-img>
                              </template>
                              <v-card>
                                <v-card-action class="d-flex justify-end">
                                  <v-card-text class="title font-weight-light">
                                    Шаг 3. Нажать на кнопку "Потушить возгорание"
                                  </v-card-text>
                                    <v-btn
                                      class="ma-2"
                                      color="red"
                                      dark
                                      @click="dialog3 = false"
                                    >
                                      Закрыть
                                      <v-icon
                                        dark
                                        right
                                      >
                                        mdi-cancel
                                      </v-icon>
                                    </v-btn>
                                </v-card-action>

                                  <v-img
                                    lazy-src="/img/material/check.png"
                                    max-height="800"
                                    max-width="100%"
                                    src="/img/material/check.png"
                                    contain
                                  ></v-img>
                              </v-card>
                            </v-dialog>
                            <v-sheet class="d-flex justify-center">
                              <v-card flat>

                                <v-card-title class=" title font-weight-light">
                                  <v-icon color="red" size="40" class='mr-3'>mdi-magnify-scan</v-icon>
                                  Нажмите на изображение для увеличения
                                </v-card-title>
                              </v-card>
                            </v-sheet>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>

                  </v-card>
                  <div width="100%" class="d-flex justify-end">
                    <v-btn
                      color="red"
                      dark
                      @click="e1 = 4"
                    >
                      Далее
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="4">
                  <v-card
                    class="d-flex align-center justify-center mb-12"
                    height="300px"
                  >
                    <v-img
                      lazy-src="img/material/steps.svg"
                      max-height="50%"
                      max-width="30%"
                      contain
                      src="img/material/steps.svg"
                      >
                    </v-img>
                  <div>
                    <!-- <v-card-title class="headline font-weight-light pb-1">
                      Кратко еще раз:
                    </v-card-title>
                    <v-card-text class="title font-weight-light pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      ознакомиться с типом пожара;
                    </v-card-text>
                    <v-card-text class="title font-weight-light pb-0">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      выбрать подходящий тип огнетушителя;
                    </v-card-text>
                    <v-card-text class="title font-weight-light pb-5">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      нажать кнопку "Потушить возгорание"
                    </v-card-text> -->
                    <v-btn color="green" dark to="/workshop4">
                      Мне все понятно, перейти к выполнению задания
                    </v-btn>

                  </div>

                  </v-card>

                  <v-btn
                    color="red"
                    @click="e1 = 1"
                    dark
                  >
                    Посмотреть инструкцию еще раз
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data () {
    return {
      e1: 1,
      dialog1: false,
      dialog2: false
    }
  }
}
</script>
