<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-center align-center mt-5">
            <v-img
              lazy-src="img/material/evacuation-plan.svg"
              max-height="100%"
              max-width="10%"
              contain
              src="img/material/evacuation-plan.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light ml-5">
              <span class="font-weight-bold">Виртуальный тренажер по эвакуации из здания при возникновении пожара</span>
              <v-card-text class="title font-weight-light">

              </v-card-text>
            </v-card-text>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <!-- <v-card-title>Исходные данные</v-card-title> -->
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-tabs v-model="tabs" color="red" grow>
              <v-tab>Задание</v-tab>
              <v-tab>1. План эвакуации</v-tab>
              <v-tab>2. 3D модель здания</v-tab>
              <v-tab>3. Виртуальный тренажер по эвакуации</v-tab>
              <v-tab>4. Анализ</v-tab>
            </v-tabs>
          </v-card>

        </v-col>
        <v-col cols="12">
          <v-card>
          <v-tabs-items v-model="tabs">
            <v-tab-item>
              <v-card class="pa-5" flat>
                <v-card-title>
                  Для выполнения задания необходимо:
                </v-card-title>
                <v-card
                class="pa-1 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-1</v-icon>
                  <v-card-text class="title font-weight-light">
                    Подробно ознакомиться с планом эвакуации;
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-1 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-2</v-icon>
                  <v-card-text class="title font-weight-light">
                    Изучить 3D модель здания и сравнить с предложенным планом эвакуации;
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-1 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-3</v-icon>
                  <v-card-text class="title font-weight-light">
                    Используя мышь и клавиатуру виртуально эвакуироваться из здания за указанный промежуток времени;
                  </v-card-text>
                </v-card>
                <v-card
                class="pa-1 d-flex justify-center align-center"
                full-width
                flat

                >
                  <v-icon color="red" size="100">mdi-numeric-4</v-icon>
                  <v-card-text class="title font-weight-light">
                    При успешной эвакуации провести анализ соответсвия плана эвакуации и предложенной 3D модели помещения, выявить нарушения, связанные с требованиями пожарной безопасности к помещениям.
                  </v-card-text>
                </v-card>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card class="d-flex justify-center">
                <v-img
                  lazy-src="img/material/currPlan2.jpg"
                  max-height="80%"
                  max-width="80%"
                  contain
                  src="img/material/currPlan2.jpg"
                >
                </v-img>
              </v-card>

            </v-tab-item>
            <v-tab-item>
              <model-viewer
                style="height:600px;width:100%; outline:red;"
                src="img/3d/planImgGrey.glb" alt="A 3D model of an astronaut"
                camera-orbit="0deg 10deg 5m"
                camera-target="0m 0m 0m"
                camera-controls>
              </model-viewer>
            </v-tab-item>
            <v-tab-item>
              <v-row class="pa-10">
                <v-col cols=8>
                  <v-card
                    flat
                    class="d-flex pa-3"

                  >
                    <v-img
                      lazy-src="img/material/stopwatch.svg"
                      max-height="100%"
                      max-width="10%"
                      contain
                      src="img/material/stopwatch.svg"
                    >
                    </v-img>
                    <v-card-text class="title font-weight-light">
                      Время на эвакуацию: 1 минута
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="d-flex pa-3"

                  >
                    <v-img
                      lazy-src="img/material/circular-arrow.svg"
                      max-height="100%"
                      max-width="10%"
                      contain
                      src="img/material/circular-arrow.svg"
                    >
                    </v-img>
                    <v-card-text class="title font-weight-light">
                      Количество попыток: не ограничено
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="d-flex pa-3"

                  >
                    <v-img
                      lazy-src="img/material/internet.svg"
                      max-height="100%"
                      max-width="10%"
                      contain
                      src="img/material/internet.svg"
                    >
                    </v-img>
                    <v-card-text class="title font-weight-light">
                      Рекомендуемый браузер: Google Chrome
                    </v-card-text>
                  </v-card>
                  <v-card
                    flat
                    class="d-flex pa-3"

                  >
                    <v-img
                      lazy-src="img/material/cpu2.svg"
                      max-height="100%"
                      max-width="10%"
                      contain
                      src="img/material/cpu2.svg"
                    >
                    </v-img>
                    <v-card-text class="title font-weight-light">
                      Минимальные системные требования:
                    <ul>
                      <li>2 гигабайта оперативной памяти;</li>
                      <li>Двухъядерный процессор intel core duo.</li>
                    </ul>
                    </v-card-text>

                  </v-card>
                </v-col>
                <v-col cols="4" class="d-flex flex-column jystify-center align-center">
                  <v-card flat class="d-flex flex-column jystify-center align-center mt-10">
                    <v-btn
                    color="red"
                    dark
                    block
                    height="100px"
                    target="_blank"
                    href="http://test.umcmrg.ru/evacuation/"
                    >
                      Перейти к тренажеру
                    </v-btn>
                    <!-- <v-card-text class="text-center">
                      откроется в новой вкладке браузера
                    </v-card-text> -->
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-container>
              <v-row>
                <v-col
                cols="12"
                md="8"
                >
                <div v-if="!answer">
                  <v-card flat>
                    <v-card-title class="font-weight-light">
                      Впишите выявленные нарушения, связанные с требованиями<br> пожарной безопасности к помещениям.
                    </v-card-title>
                    <v-textarea
                      solo
                      counter
                      class="pa-5"
                      name="input-7-4"
                      rows="15"
                      label="Впишите выявленные нарушения"
                      v-model.trim="textAnswer"
                    ></v-textarea>
                  </v-card>
                </div>
                <div v-if="answer === true">
                  <v-card flat>
                    <v-card-title class="font-weight-light">
                      Сравните свой ответ с перечнем указанных нарушений
                    </v-card-title>
                    <v-tabs v-model="tabs2" color="red" grow>
                      <v-tab>Перечень нарушений</v-tab>
                      <v-tab>Ваш ответ</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tabs2">
                      <v-tab-item class="pa-5">
                        <v-card-text class="title font-weight-light">
                          1. Захламлен эвакуационный выход в помещении №2;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          2. Отсутствует кнопка включения средств и систем пожарной автоматики в помещении №2;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          3. Отсутствует огнетушитель в помещении №2;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          4. Отсутствует телефон в помещении №13;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          5. Отсутствует телефон в помещении №14;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          6. Отсутствует план эвакуации;
                        </v-card-text>
                        <v-card-text class="title font-weight-light">
                          7. Отсутствуют знаки эвакуационных путей.
                        </v-card-text>
                      </v-tab-item>
                      <v-tab-item class="pa-5">
                        <v-card-text class="title font-weight-light">
                        {{ textAnswer }}
                        </v-card-text>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </div>
                </v-col>
                <v-col
                cols="12"
                md="4"
                >
                <!-- <v-card-title class="font-weight-light mb-5">
                  Осмотреть здание
                </v-card-title> -->
                <v-card
                flat
                class="d-flex flex-column justify-center"
                >
                  <!-- <v-btn
                  color="red"
                  dark
                  height="50"
                  class="mb-5"
                  target="_blank"
                  href="http://test.umcmrg.ru/evacuation/"
                  >
                    Анализ помещения
                  </v-btn> -->
                <v-dialog
                  class="d-flex justify-center"
                  v-model="dialog"
                  max-width="50%"
                  max-height="800"
                >
                    <template v-slot:activator="{ on }">
                    <v-btn
                      color="red"
                      dark
                      height="50"
                      class="mb-5"
                      v-on="on"
                      >
                        План эвакуации с обозначениями
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-action class="d-flex justify-end">
                          <v-btn
                            class="ma-2"
                            text
                            color="red"
                            @click="dialog = false"
                          >
                            Закрыть
                            <!-- <v-icon
                              dark
                              right
                            >
                              mdi-cancel
                            </v-icon> -->
                          </v-btn>
                      </v-card-action>

                        <v-img
                          lazy-src="img/material/currPlanDesk.jpg"
                          max-height="800"
                          max-width="100%"
                          src="img/material/currPlanDesk.jpg"
                          contain
                        ></v-img>
                    </v-card>
                  </v-dialog>
                </v-card>
                <v-card-title class="font-weight-light mb-5">
                  Проверьте себя
                </v-card-title>
                <v-card
                flat
                class="d-flex flex-column justify-center"
                >
                  <v-btn
                  color="green"
                  dark
                  height="50"
                  class="mb-5"
                  @click="check"
                  >
                    Проверить
                  </v-btn>
                  <v-btn
                  height="50"
                  class="mb-5"
                  :disabled="disabled"
                  to="/theme4"
                  >
                    Перейти к следующей теме
                  </v-btn>
                  <v-card-text>
                    {{ msg }}
                  </v-card-text>
                </v-card>
                </v-col>
              </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
          </v-card>
          <Hint></Hint>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tabs: null,
    tabs2: null,
    answer: false,
    disabled: true,
    textAnswer: null,
    dialog: null,
    msg: ''
  }),
  components: {
    Hint
  },
  methods: {
    check: function () {
      if (this.textAnswer === null || this.textAnswer === '') {
        this.msg = 'Заполните поле для выявленных нарушений'
      } else {
        this.disabled = false
        this.answer = true
        this.msg = ''
      }
    }
  }
}
</script>
