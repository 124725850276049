<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="d-flex mt-10 mb-0">
            <v-img
              lazy-src="img/material/to-do-list.svg"
              max-height="100%"
              max-width="10%"
              contain
              src="img/material/to-do-list.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light ml-5">
              <span class="font-weight-bold">Задание:</span> Виртуально погасить возорание, выбрав первичные средства пожаротушения в соответствии с классом пожара.
            </v-card-text>
                        <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Пошаговая инструкция.</span> Нажимайте на кнопку "далее" для изучения информации.
            </v-card-text>
          </div>
        </v-col>
        <v-col cols="12">
          <Tutorial></Tutorial>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Tutorial from '@/components/Workshop4/Tutorial.vue'
export default {
  data () {
    return {
      //
    }
  },
  components: {
    Tutorial
  }
}
</script>
