<template>
  <div>
    <v-container>
      <v-row class="d-flex justify-center align-start">
        <v-col cols="12">
          <div class="d-flex mt-10">
            <v-img
              lazy-src="img/material/to-do-list.svg"
              max-height="100%"
              max-width="10%"
              contain
              src="img/material/to-do-list.svg"
            >
            </v-img>
            <v-card-text class="title font-weight-light ml-5">
              <span class="font-weight-bold">Задание:</span> Расшифруйте условные сокращения основных терминов и понятий в области пожарной безопасности.
            </v-card-text>
          </div>
        </v-col>
        <!-- <v-col cols="12">
          <v-img
            lazy-src="img/material/bg-abb-1.svg"
            max-width="100%"
            height="300"
            contain
            src="img/material/bg-abb.svg"
          >
          </v-img>
        </v-col> -->
        <v-col cols="12">
          <div v-if="abbTotalRez === null">
          <div
            v-for="termin in termins"
            :key="termin.id"
            class=""
          >
          <div
           v-show="termin.id === stepIndex"
          >
            <v-card-title class="title font-weight-light text-center">
              Условное сокращение
            </v-card-title>
            <v-card
            flat
            height="200"
            class="d-flex justify-center align-center pa-10 ptm-card-abb"

            >
              <div class="d-flex flex-column justify-center align-center">
                <v-card-title class="display-4 font-weight-bold">
                  {{ termin.smallTitle }}
                </v-card-title>
                <!-- <v-card-title class="title">
                  {{ inputField }}
                </v-card-title> -->
              </div>
            </v-card>

            <v-col cols="12" class="mt-10 mb-16">
              <v-text-field
                v-model="inputField"
                class="ptm-term-field"
                label="Введите расшифровку"
                color="red"
              ></v-text-field>
              <div class="d-flex justify-center mt-10">
                <v-btn
                height="50"
                width="400"
                color="red"
                dark
                @click="check(termin.fullTitle, termin.smallTitle, termin.titleTrue, termin.definition)"
                >
                  Проверить
                </v-btn>
              </div>
            </v-col>
          </div>
          </div>
          </div>
        </v-col>
        <v-col cols="6">
          <div v-if="abbTotalRez === null">
           <div v-show="stepIndex <= termins.length">
            <v-card flat class="d-flex flex-column justify-center align-center pa-10">
            <v-card-title class="title font-weight-light">
              {{ stepIndex }} из {{ termins.length }}
            </v-card-title>
              <v-progress-linear color="red" :value="progress">
              </v-progress-linear>
            </v-card>
           </div>
          </div>
        </v-col>
        <v-col cols="12">
        <div v-show="stepIndex > termins.length">
         <!-- <div> -->
          <v-card class="d-flex flex-column align-center justify-center pa-10 mb-10">
            <v-icon
              size="50"
              color="green"

            >
              mdi-check
            </v-icon>
             <h2>
              Задание завершено
            </h2>
            <v-card-title>
              Вы расшифровали верно: {{ totalResult.length }} из {{ termins.length }} условных сокращений
            </v-card-title>
          </v-card>
          <v-sheet flat class="mt-10 d-flex align-center justify-space-around">
            <v-btn
              color="red"
              @click="reloadPage"
              dark
              height="50"
              >
                <v-icon class="mr-5">mdi-selection-ellipse-arrow-inside</v-icon>
                Повторить задание
              </v-btn>
              <v-btn
              color="green"
              to="/theme2"
              height="50"
              dark
              @click="setResQuiz"
              >
                Перейти к следующей теме
                <v-icon class="ml-5">mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
          </v-sheet>
        </div>
        </v-col>
        <v-col cols="12">
        <div v-if="abbTotalRez != null">
         <!-- <div> -->
          <v-card class="d-flex flex-column align-center justify-center pa-10 mb-10">
            <v-icon
              size="50"
              color="green"

            >
              mdi-check
            </v-icon>
             <h2>
              Задание завершено
            </h2>
            <v-card-title>
              Вы расшифровали верно: {{ totalResult.length }} из {{ termins.length }} условных сокращений
            </v-card-title>
          </v-card>
          <v-sheet flat class="mt-10 d-flex align-center justify-space-around">
            <v-btn
              color="red"
              @click="reloadPage"
              dark
              height="50"
              >
                <v-icon class="mr-5">mdi-selection-ellipse-arrow-inside</v-icon>
                Повторить задание
              </v-btn>
              <v-btn
              color="green"
              to="/theme2"
              height="50"
              dark
              @click="setResQuiz"
              >
                Перейти к следующей теме
                <v-icon class="ml-5">mdi-arrow-right-circle-outline</v-icon>
              </v-btn>
          </v-sheet>
        </div>
        </v-col>
        <v-dialog
          v-model="dialog"
          width="50%"
          persistent
        >
          <v-card>
            <v-card-title :class="colorHeadDialog" class="headline white--text">
              <v-icon color="white" class="mr-2">{{ iconHeadDialog }}</v-icon>
              {{ messageDialog.title }}
            </v-card-title>
            <v-card-title class="font-weight-light">
              <span class="title">{{ inputFieldRaw }}</span>
            </v-card-title>
            <v-card-title class="font-weight-light pb-10">
              {{ messageDialog.definition }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :color="colorHeadDialog"
                text
                @click="next"
              >
                Далее
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="errorDialog"
          width=30%
        >
          <v-card>
            <v-card-title class="headline red white--text d-flex align-center">
              <v-icon color="white" class="mr-2">mdi-alert</v-icon>
              {{ messageDialog.title }}
            </v-card-title>
            <v-card-title class="font-weight-light">
              {{ messageDialog.definition }}
            </v-card-title>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red"
                text
                @click="errorDialog = false"
              >
                Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-row>
    </v-container>

  </div>
</template>
<script>

export default {
  data: () => ({
    icon: 'mdi-progress-question',
    checkColor: 'grey',
    totalResult: [],
    message: '',
    inputFieldRaw: '',
    inputField: '',
    currentFullTitle: '',
    currentSmallTitle: '',
    progress: 0,
    stepIndex: 1,
    colorClass: 'red',
    dialog: false,
    errorDialog: false,
    colorHeadDialog: '',
    iconHeadDialog: '',
    messageDialog: [
      {
        title: '',
        titleFalse: '',
        definition: ''
      }
    ],
    termins: [
      { id: 1, smallTitle: 'ГГ', fullTitle: 'горючий газ', titleTrue: 'Горючий газ', definition: 'Газообразная смесь, состоящая из метана и более тяжелых углеводородов, азота, диоксида углерода, водяных паров, серосодержащих соединений, инертных газов.' },
      { id: 2, smallTitle: 'ГЖ', fullTitle: 'горючая жидкость', titleTrue: 'Горючая жидкость', definition: 'Жидкость, способная воспламеняться при воздействии источника зажигания и самостоятельно гореть после его удаления, т.е. характеризующаяся наличием температуры воспламенения. ГЖ с температурой вспышки ниже 61°С в закрытом или 66°С в открытом тигле относится к ЛВЖ. Смесь с воздухом паров ГЖ при концентрациях между нижним (НКПР) и верхним концентрационным пределом распространения пламени (ВКПР) взрывоопасна.' },
      { id: 3, smallTitle: 'ГПН', fullTitle: 'государственный пожарный надзор', titleTrue: 'Государственный пожарный надзор', definition: 'Осуществляется в порядке, установленном законодательством РФ о пожарной безопасности, должностными лицами органов ГПН, находящихся в ведении федерального органа исполнительной власти, уполномоченного на решение задач в области пожарной безопасности (ПБ). Основной задачей ГПН является защита жизни и здоровья граждан, их имущества, государственного и муниципального имущества, а также имущества организаций от пожаров и ограничение их последствий.' },
      { id: 4, smallTitle: 'ДПД', fullTitle: 'добровольная пожарная дружина', titleTrue: 'Добровольная пожарная дружина', definition: 'Оперативное подразделение добровольной пожарной охраны, на вооружении которого имеются пожарные машины. Независимо от организационно-правовой формы, ведомственной принадлежности и места дислокации оперативные подразделения добровольной пожарной охраны входят в состав гарнизона пожарной охраны, включены в расписание его выездов, утвержденное органом местного самоуправления, и в оперативном отношении подчиняются ФПС. Их деятельность осуществляется в соответствии с уставами (положениями), зарегистрированными в установленном порядке, и подлежит лицензированию.' },
      { id: 5, smallTitle: 'ДЮП', fullTitle: 'дружина юных пожарных', titleTrue: 'Дружина юных пожарных', definition: 'Добровольное противопожарное формирование детей и подростков, которое создаётся в целях воспитания у них профессиональных пожарно-технических навыков, гражданского мужества, благородства, находчивости, коллективизма и творчества, а также физической закалки, что даёт возможность овладеть основами пожарного дела. Основными задачами ДЮП являются: оказание помощи дошкольным и школьным учреждениям в воспитании у детей чувства ответственности за сохранность жизни и здоровья людей, материальных ценностей от пожаров; противопожарная пропаганда и агитация, пожарно-профилактическая работа среди детей, подростков, молодёжи; пропаганда традиций и истории пожарной охраны и добровольного пожарного общества.' },
      { id: 6, smallTitle: 'ЛВЖ', fullTitle: 'легковоспламеняющаяся жидкость', titleTrue: 'Легковоспламеняющаяся жидкость', definition: 'Горючая жидкость с температурой вспышки не более 61°С в закрытом тигле или 66°С в открытом тигле.' },
      { id: 7, smallTitle: 'НПБ', fullTitle: 'нормы пожарной безопасности', titleTrue: 'Нормы пожарной безопасности', definition: 'Нормативный документ по пожарной безопасности, устанавливающий общие принципы, количественные и качественные критерии, требования пожарной безопасности к однородным группам объектов защиты на стадиях их проектирования, реконструкции, изготовления и строительства, а также к пожарно-технической продукции и организационно-техническим мероприятиям по обеспечению пожарной безопасности.' },
      { id: 8, smallTitle: 'НТД', fullTitle: 'научно-техническая документация', titleTrue: 'Научно-техническая документация', definition: 'К научно-техническим документам относятся документы, в которых отражена работа, связанная с осуществлением научной, научно-исследовательской, научно-технической деятельности и экспериментальных разработок.' },
      { id: 9, smallTitle: 'ОБЖ', fullTitle: 'основы безопасности жизнедеятельности', titleTrue: 'Основы безопасности жизнедеятельности', definition: 'Учебный предмет, изучаемый в учреждениях начального, общего и среднего профессионального Министерство образования РФ рекомендовало изучение курса ОБЖ в общеобразовательных учреждениях во всех классах (с 1 по 11 класс). Инициаторами введения курса ОБЖ стали Министерство образования РСФСР и Государственный комитет по делам гражданской обороны, чрезвычайным ситуациям и ликвидации последствий стихийных бедствий.' },
      { id: 10, smallTitle: 'ОСТ', fullTitle: 'отраслевой стандарт', titleTrue: 'Отраслевой стандарт', definition: 'Документ по стандартизации, утвержденный (принятый) до 1 июля 2003 г. федеральным органом исполнительной власти в пределах его компетенции. В настоящее время в России может устанавливать требования к оборонной продукции, а также процессам и иным объектам стандартизации, связанным с такой продукцией (отраслевые стандарты применяются до их отмены, разработки на их основе иных документов по стандартизации оборонной продукции или перевода в категорию стандартов организаций).' },
      { id: 11, smallTitle: 'БЖД', fullTitle: 'безопасность жизнедеятельности', titleTrue: 'Безопасность жизнедеятельности', definition: 'Основная цель БЖД как науки — защита человека в техносфере от негативных опасностей (воздействий) антропогенного и естественного происхождения и достижения комфортных или безопасных условий жизнедеятельности .' },
      { id: 12, smallTitle: 'ПБ', fullTitle: 'пожарная безопасность', titleTrue: 'Пожарная безопасность', definition: 'состояние защищённости личности, имущества, общества и государства от пожаров. Это определение повторяет аналогичные для любых видов безопасности: состояние защищенности любого объекта от любых видов опасности. Как это состояние обеспечить на практике не знает никто. Определение возникло до катастрофы в Чернобыле, когда существовала парадигма "абсолютной" безопасности. После катастрофы возникла парадигма приемлемого риска, и пожарная безопасность определяется как состояние объекта защиты, при котором значения всех пожарных рисков, связанных с данным объектом, не превышают допустимых значений.' },
      { id: 13, smallTitle: 'ПО', fullTitle: 'предел огнестойкости', titleTrue: 'Предел огнестойкости', definition: 'Показатель сопротивляемости конструкции огню' },
      { id: 14, smallTitle: 'ППБ', fullTitle: 'правила пожарной безопасности', titleTrue: 'Правила пожарной безопасности', definition: 'Комплекс положений, устанавливающих порядок соблюдения требований и норм пожарной безопасности при строительстве и эксплуатации объекта.' },
      { id: 15, smallTitle: 'ПРО', fullTitle: 'предел распространения огня', titleTrue: 'Предел распространения огня', definition: 'Максимальное расстояние в любую сторону от зоны действия внутреннего или внешнего источника зажигания, на которое распространяется горение.' },
      { id: 16, smallTitle: 'ПУЭ', fullTitle: 'правила устройства электроустановок', titleTrue: 'Правила устройства электроустановок', definition: 'Группа общесоюзных нормативных документов Минэнерго СССР, Минэнерго России и некоторых других стран. Эти правила не являются единым документом, издавались и продолжают издаваться отдельными главами, одна из которых называется «Общая часть» и устанавливает общие требования. Не являются документом в области стандартизации. Сборники документов принято именовать «изданиями». В данный момент на территории различных государств действуют различные версии документов: в России 6 и 7-е (переизданные главы) издания, на Украине издание ПУЭ-2017, в Белоруссии 6-е издание и так далее.' },
      { id: 17, smallTitle: 'СНиП', fullTitle: 'строительные нормы и правила', titleTrue: 'Строительные нормы и правила', definition: 'Совокупность принятых органами исполнительной власти нормативных актов технического, экономического и правового характера, регламентирующих осуществление градостроительной деятельности, а также инженерных изысканий, архитектурно-строительного проектирования и строительства.' },
      { id: 18, smallTitle: 'УЗО', fullTitle: 'устройство защитного отключения', titleTrue: 'Устройство защитного отключения', definition: 'Является наиболее эффективным средством защиты по сравнению с автоматическими выключателями, так как обеспечивает защиту при непосредственном контакте человека с электрооборудованием или проводником. Кроме того, УЗО предупреждает опасность возникновения пожара в результате замыкания электропроводки.' }
    ],
    abbTotalRez: null
  }),
  methods: {
    check: function ($fullTitle, $smallTitle, $titleTrue, $definition) {
      this.currentFullTitle = $fullTitle
      this.currentSmallTitle = $smallTitle
      this.inputFieldRaw = $titleTrue
      this.inputField = this.inputField.toLowerCase().trim()

      if (this.inputField === this.currentFullTitle) {
        this.dialog = true
        this.colorHeadDialog = 'green'
        this.messageDialog.title = 'Верно'
        this.messageDialog.definition = $definition
        this.iconHeadDialog = 'mdi-check-circle-outline'
        this.totalResult.push(true)
      } else if (this.inputField === '') {
        this.errorDialog = true
        this.messageDialog.title = 'Ошибка'
        this.messageDialog.definition = 'Введите расшифровку условного обозначения'
      } else {
        this.dialog = true
        this.messageDialog.title = 'Не верно!'
        this.iconHeadDialog = 'mdi-alert-circle-outline'
        this.colorHeadDialog = 'red'
        console.log(this.inputField)
        this.messageDialog.definition = ' Не верная расшифровка. Изучите условные сокращения и повторите попытку.'
      }
    },
    next: function () {
      const valProgress = 100 / this.termins.length
      this.progress += valProgress
      this.colorHeadDialog = ''
      this.stepIndex++
      this.dialog = false
      this.inputField = ''
      console.log(this.stepIndex)
    },
    reloadPage: function () {
      this.$router.go(this.$router.currentRoute)
      localStorage.removeItem('abbTotalRez')
    },
    setResQuiz: function () {
      localStorage.abbTotalRez = this.totalResult.length
      // abbTotalRez = localStorage.abbTotalRez
    }
  },
  mounted () {
    if (localStorage.abbTotalRez) {
      this.abbTotalRez = localStorage.abbTotalRez
    }
  }
}
</script>
<style lang="less" scoped>
.ptm-term-field {
  font-size: 24px;
  margin: 10px;
  height: 50px;
}
.ptm-card-abb {
  background-image: url(/img/material/bg-abb-red-2.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
