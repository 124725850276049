<template>
  <v-container class="">
    <v-row>
     <v-col
      cols="12"
      >
        <v-card-text class="headline">
          Тема: "Меры ПБ в зданиях и помещениях с массовым скоплением людей"
        </v-card-text>
     </v-col>
           <!-- img -->
      <v-col cols="12">
        <v-row>
          <v-col cols="6">
            <div class="ptm-timeline-theme">
            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                v-for="theme in themes1"
                :key="theme.id"
                :color="theme.color"
                small
              >
                <div>
                  <div class="headline font-weight-light">{{ theme.theme }}</div>
                </div>
              </v-timeline-item>
            </v-timeline>
              <v-timeline
                align-top
                dense
              >
                <v-timeline-item
                  v-for="theme in themes2"
                  :key="theme.id"
                  :color="theme.color"
                  small
                >
                  <div>
                    <div class="headline font-weight-light">{{ theme.theme }}</div>
                  </div>
                </v-timeline-item>
            </v-timeline>

            </div>
          </v-col>
          <v-col cols="6">
            <v-card class="pa-5 mb-5">
              <v-img
                height="200px"
                src="img/material/bg-theme-3.svg"
                contain
                class="pa-5"
              >
              </v-img>
            </v-card>
            <v-card flat>
              <v-card-text class="subtitle-1">
                <v-icon
                color="red"
                class="mr-3"
                >
                mdi-cursor-default-click-outline</v-icon>
                Для изучения дополнительной информации, нажимайте на кнопки или активные элементы
              </v-card-text>
              <v-card-text class="subtitle-1">
                <v-icon
                color="red"
                class="mr-3"
                >
                mdi-arrow-left-right</v-icon>
                Для перемещения по разделам используйте кнопки "Вперед" и "Назад" в виде стрелок
              </v-card-text>
            </v-card>
              <v-card flat class="d-flex justify-center mt-10">
                <v-btn
                color="red"
                dark
                to="/page12"
                >
                  Перейти к изучению
                </v-btn>
              </v-card>
          </v-col>
        </v-row>
      </v-col>
      <!-- /theme -->
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    themes1: [
      {
        id: 1,
        theme: 'Меры ПБ при эксплуатации электрических сетей, электрооборудования и электронагревательных приборов',
        color: 'red'
      },
      {
        id: 2,
        theme: 'Общие правила при эксплуатации электроустановок, электросетей, электрооборудования',
        color: 'red'
      },
      {
        id: 3,
        theme: 'Требования ПБ при работе с электроприборами, офисным оборудованием и оргтехникой',
        color: 'red'
      },
      {
        id: 4,
        theme: 'Запрещенные действия',
        color: 'red'
      },
      {
        id: 5,
        theme: 'Аварийные режимы работы электроустановок',
        color: 'red'
      },
      {
        id: 6,
        theme: 'Воспламеняющиеся жидкости',
        color: 'red'
      },
      {
        id: 7,
        theme: 'Пожаровзрывоопасность',
        color: 'red'
      },
      {
        id: 8,
        theme: 'Хранение, обращение, транспортирование ЛВЖ, ГЖ, ГГ',
        color: 'red'
      }
    ],
    themes2: [
      {
        id: 1,
        theme: 'Разработка плана эвакуации людей при пожаре, содержание путей эвакуации',
        color: 'red'
      },
      {
        id: 2,
        theme: 'Эвакуационные выходы',
        color: 'red'
      },
      {
        id: 3,
        theme: 'Запрещенные дейстия при эксплуатации эвакуационныз путей, эвакуационных и аварийных выходов',
        color: 'red'
      },
      {
        id: 4,
        theme: 'Пользование лифтами во время пожара',
        color: 'red'
      },
      {
        id: 5,
        theme: 'Особенности распростронения огня в зданиях повышенной этажности',
        color: 'red'
      },
      {
        id: 6,
        theme: 'Повышенная опасность продуктов горения',
        color: 'red'
      },
      {
        id: 7,
        theme: 'Незадымляемые лестничные клетки',
        color: 'red'
      },
      {
        id: 8,
        theme: 'Порядок хранения печатной продукции и документов',
        color: 'red'
      }
    ]
  })
}
</script>
<style lang="less" scoped>
.ptm-timeline-theme {
  overflow: auto;
  height: 70vh;
}
</style>
