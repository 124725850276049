<template>
  <div>
          <!-- <iframe width="960" height="800" src="http://test.umcmrg.ru/evacuation/" frameborder="0"></iframe> -->
          <!-- <vue-friendly-iframe src="http://test.umcmrg.ru/evacuation/" ></vue-friendly-iframe> -->
            <!-- <v-col cols="8">
          <model-viewer
            style="height:800px;width:100%; outline:red;"
            src="img/3d/plan.gltf" alt="A 3D model of an astronaut"
            camera-orbit="390deg 80deg 20m"
            camera-target="0m 1m 0m"
            camera-controls>
          </model-viewer>
        </v-col> -->
  </div>
</template>
<script>
// import VueFriendlyIframe from 'vue-friendly-iframe'
export default {

}
</script>
