<template>
  <div class="ptm-block-relative">
    <v-container>
      <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <v-card-text class="title font-weight-light ml-5">
            <span class="font-weight-bold">Виртуально погасить возгорание, используя подходящий для этого тип огнетушителя</span>
          </v-card-text>
        </div>
      </v-col>
      </v-row>

    <div v-for="item in questions" :key="item.id">
    <v-row class="align-center justify-center" v-show="item.id === questionIndex">
    <v-col cols="4">
      <div>
        <v-card-text class="title pa-2 pb-5 text-center">
          Класс пожара: <span class="font-weight-light">{{ item.material }}</span>
        </v-card-text>
      </div>

      <div v-if="selectAnswer === true">
        <v-img
          :lazy-src="item.srcMaterialTrue"
          width="90%"
          contain
          :src="item.srcMaterialTrue"
          class="ma-10"
        ></v-img>
      </div>
      <div v-else>
        <v-img
          :lazy-src="item.srcMaterial"
          width="90%"
          contain
          :src="item.srcMaterial"
          class="ma-10"
        ></v-img>
      </div>

    </v-col>
    <v-col cols="5">
      <div>
        <v-card-text class="title pa-2 text-center">
          Тип огнетушителя:
        </v-card-text>

      </div>
      <v-card flat width="100%" class="mb-2">
        <div class="pa-2">
        <v-img
          :lazy-src="srcChoice"
          width="60%"
          height="300"
          contain
          :src="srcChoice"
          class="ma-auto"
        >
        </v-img>
        </div>
        <v-card-text class="title pa-0 font-weight-light text-center">
          <div v-if="currentSelectTitle != ''">
            {{ currentSelectTitle }}
          </div>
          <div v-else>
            Огнетушитель не выбран
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="3">
      <v-btn
        color=""
        block
        :disabled="checkEnabled"
        @click="check"
        class="mt-5 mr-5"
        height="50"
        >Потушить возгорание
      </v-btn>

      <v-btn
        color="red"
        :disabled="nextEnabled"
        block
        @click="next"
        height="50"
        class="mt-5  white--text"
        >Далее
      </v-btn>
      <v-card flat   class="mb-2 d-flex justify-center align-center" >
        <div class="pa-1 d-flex flex-column text-center justify-center align-center">
          <v-card-title  v-html="message">
          </v-card-title>
          <v-icon size="100" :color="checkColor" class="ma-auto">{{ icon }}</v-icon>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card-title class="text-center">Выберите огнетушитель:</v-card-title>
    </v-col>
      <v-col cols="4" v-for="(inItem, index) in item.choice" :key="index">
        <v-card
          tag="a"
          hover
          class="d-flex flex-column justify-center pa-3"
          @click="select(inItem.id,  inItem.type, inItem.src, inItem.title, inItem.correct)"
          active-class="ptm-active-card"
          :disabled="!cardEnabled"
          >
          <v-card :class="colorClass" class="white--text mb-2 d-flex justify-center align-center">
            <v-card-title class="headline text-center pa-2">
              {{ inItem.type }}
            </v-card-title>
            </v-card>
            <v-img
                :lazy-src="inItem.src"
                width="50%"
                contain
                :src="inItem.src"
                class="ma-auto"
              >
            </v-img>
          </v-card>
        </v-col>
      </v-row>

    </div>
    <v-row>
        <v-col cols="12">
          <div v-show="questionIndex > questions.length">
            <v-card class="d-flex flex-column align-center justify-center pa-10 mb-10">
              <v-icon
                size="50"
                color="green"
              >
                mdi-check
              </v-icon>
              <h2>
                Задание завершено
              </h2>
              <v-card-title>
                Успешно потушено очагов возгорания: {{ workShop1Res.length }}  из {{ questions.length }}
              </v-card-title>
            </v-card>
            <v-sheet flat class="mt-10 d-flex align-center justify-space-around">
              <v-btn
                text
                @click="reloadPage"
                >
                  <v-icon class="mr-5">mdi-selection-ellipse-arrow-inside</v-icon>
                  Пройти задание еще раз
                </v-btn>
                <v-btn
                text
                to="/theme5"
                >
                  Перейти к следующей теме
                  <v-icon class="ml-5">mdi-arrow-right-circle-outline</v-icon>
                </v-btn>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>
<script>
export default {
  data: () => ({
    hint: false,
    step: 1,
    workShop1Res: [],
    questionIndex: 1,
    currentSelectTitle: '',
    currentSelectSrc: '',
    currentSelectAnswer: '',
    selectAnswer: '',
    checkColor: 'grey',
    message: '',
    checkEnabled: false,
    nextEnabled: true,
    cardEnabled: true,
    colorClass: 'red',
    srcChoice: 'img/material/noChoice.png',
    icon: 'mdi-progress-question',
    questions: [
      {
        id: 1,
        material: 'E',
        srcMaterial: 'img/material/fire-electronic-1.svg',
        srcMaterialTrue: 'img/material/fire-electronic-stop-1.svg',
        type: 'radio',
        choice: [
          { id: '1-op', title: 'Водный (ОВ)', type: 'ОВ', src: 'img/material/OV.png', correct: true },
          { id: '1-ovp', title: 'Воздушно-пенный (ОВП)', type: 'ОВП', src: 'img/material/OVP.png' },
          { id: '1-ou', title: 'Углекислотный (ОУ)', type: 'ОУ', src: 'img/material/OU.png', correct: true }
        ]
      },
      {
        id: 2,
        material: 'B',
        srcMaterial: 'img/material/fire-barrel.svg',
        srcMaterialTrue: 'img/material/fire-barrel-stop.svg',
        type: 'radio',
        choice: [
          { id: '2-op', title: 'Водный (ОВ)', type: 'ОВ', src: 'img/material/OV.png', correct: true },
          { id: '2-ovp', title: 'Воздушно-пенный (ОВП)', type: 'ОВП', src: 'img/material/OVP.png', correct: true },
          { id: '2-ou', title: 'Углекислотный (ОУ)', type: 'ОУ', src: 'img/material/OU.png', correct: true }
        ]
      },
      {
        id: 3,
        material: 'C',
        srcMaterial: 'img/material/gas-burning.svg',
        srcMaterialTrue: 'img/material/gas-burning-stop.svg',
        type: 'radio',
        choice: [
          { id: '3-op', title: 'Водный (ОВ)', type: 'ОВ', src: 'img/material/OV.png' },
          { id: '3-ovp', title: 'Воздушно-пенный (ОВП)', type: 'ОВП', src: 'img/material/OVP.png' },
          { id: '3-op', title: 'Порошковый закачной (ОП)', type: 'ОП', src: 'img/material/OP.png', correct: true }
        ]
      },
      {
        id: 4,
        material: 'A',
        srcMaterial: 'img/material/fire-wood.svg',
        srcMaterialTrue: 'img/material/fire-wood-stop.svg',
        type: 'radio',
        choice: [
          { id: '4-op', title: 'Водный (ОВ)', type: 'ОВ', src: 'img/material/OV.png', correct: true },
          { id: '4-op', title: 'Порошковый закачной (ОП)', type: 'ОП', src: 'img/material/OP.png', correct: true },
          { id: '4-ou', title: 'Углекислотный (ОУ)', type: 'ОУ', src: 'img/material/OU.png' }
        ]
      },
      {
        id: 5,
        material: 'B1 (подкласс)',
        srcMaterial: 'img/material/fire-bbq.svg',
        srcMaterialTrue: 'img/material/fire-bbq-stop.svg',
        type: 'radio',
        choice: [
          { id: '5-op', title: 'Водный (ОВ)', type: 'ОВ', src: 'img/material/OV.png', correct: true },
          { id: '5-op', title: 'Порошковый закачной (ОП)', type: 'ОП', src: 'img/material/OP.png', correct: true },
          { id: '5-ou', title: 'Углекислотный (ОУ)', type: 'ОУ', src: 'img/material/OU.png', correct: true }
        ]
      },
      {
        id: 6,
        material: 'А2 (подкласс)',
        srcMaterial: 'img/material/fire-rubber.svg',
        srcMaterialTrue: 'img/material/fire-rubber-stop.svg',
        type: 'radio',
        choice: [
          { id: '6-op', title: 'Водный (ОВ)', type: 'ОВ', src: 'img/material/OV.png', correct: true },
          { id: '6-op', title: 'Порошковый закачной (ОП)', type: 'ОП', src: 'img/material/OP.png', correct: true },
          { id: '6-ou', title: 'Углекислотный (ОУ)', type: 'ОУ', src: 'img/material/OU.png', correct: true }
        ]
      }
    ]
  }),
  methods: {
    select: function ($id, $type, $src, $title, $correct) {
      this.currentSelectTitle = $title
      this.currentSelectSrc = $src
      this.currentSelectAnswer = $correct
      this.srcChoice = $src
    },
    check: function () {
      this.selectAnswer = this.currentSelectAnswer
      if (this.selectAnswer === true) {
        this.icon = 'mdi-check-circle-outline'
        this.message = 'Верно!'
        this.checkColor = 'green'
        this.checkEnabled = true
        this.cardEnabled = false
        this.nextEnabled = false
        this.workShop1Res.push(true)
      } else if (this.selectAnswer === '') {
        this.message = 'Выберите<br>огнетушитель'
        this.checkColor = 'red'
      } else {
        this.icon = 'mdi-close'
        this.message = 'Не верно!'
        this.checkColor = 'red'
        this.checkEnabled = true
        this.cardEnabled = false
        this.nextEnabled = false
      }
    },
    next: function () {
      this.questionIndex++
      // this.workShop1Res[this.questionIndex].push(true)
      this.currentSelectTitle = ''
      this.currentSelectSrc = ''
      this.currentSelectAnswer = ''
      this.srcChoice = 'img/material/noChoice.png'
      this.checkEnabled = false
      this.nextEnabled = true
      this.cardEnabled = true
      this.icon = 'mdi-progress-question'
      this.checkColor = 'grey'
      this.message = ''
      this.selectAnswer = ''
    },
    reloadPage: function () {
      this.$router.go(this.$router.currentRoute)
    },
    openHint: function () {
      this.hint = true
    },
    closeHint: function () {
      this.hint = false
    }
  },
  computed: {
    currentTitle () {
      switch (this.step) {
        case 1: return 'Шаг 1. Класс пожара'
        case 2: return 'Шаг 2. Тип огнетушителя'
        case 3: return 'Шаг 3. Потушить возгарания'
        case 4: return 'Шаг 4. Результат'
        default: return 'Пошаговая инструкция'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ptm-block-relative {
  position: relative;
}
.ptm-btn-hint {
  position: absolute;
  top: 10%;
  right: 20%;
}
</style>
